import React, { memo, useContext } from "react";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";

import { ScrollContext } from "../../gatsby-browser";

const Hero = () => {
  const introductionImage = useStaticQuery(graphql`
    query introductionImageQuery {
      allFile(filter: { sourceInstanceName: { eq: "introductionImage" } }) {
        nodes {
          childImageSharp {
            fluid(maxWidth: 3200) {
              base64
              aspectRatio
              originalName
              src
            }
          }
        }
      }
    }
  `).allFile.nodes[0];

  const scrollTop = useContext(ScrollContext);

  return (
    <section className="relative">
      <Img
        className="w-full h-256 sm:min-h-512  xl:min-h-512 bg-fixed"
        fluid={introductionImage.childImageSharp.fluid}
        imgStyle={{
          objectPosition: `100% ${25 - scrollTop / 10}%`,
        }}
        alt="Merkl Kinga bemutatkozó kép"
      />
      <div className="hidden md:flex absolute right-0 top-0 w-full h-full px-12 md:px-24 lg:px-48 flex-col items-start justify-center text-5-500">
        <h1 className="font-bold text-2xl lg:text-4xl">Merkl Kinga</h1>
        <p>Anya, fotós, szenvedélyes utazó</p>
      </div>
    </section>
  );
};

export default memo(Hero);
